import React,{useEffect, useState, Suspense} from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { Home, Header, Footer, BasicDetail} from "./Components";
// const Home = React.lazy(() => import('./Components'));
import SingleProduct from './Components/Pages/SingleProduct';
import  Aboutus  from "./Components/Pages/Aboutus";
import Banner from "./Components/Pages/Banner";
import NewsArticle from "./Components/Pages/NewsArticle";
import AuthRoute from './AuthRoute';
import SiteMap from './Components/Pages/SiteMap';
import SafetyTips from './Components/Pages/SafetyTips';
import Stores from './Components/Pages/Stores';
import NewsArticleSingle from './Components/Pages/NewsArticleSingle';
// import ScrollToTop from './Components/Tools/ScrollToTop';
import Login from './Components/Layouts/Btmodal/login';
import Signup from './Components/Layouts/Btmodal/signup';
import Profile from './Components/Pages/Profile';
import DealsDetails from './Components/Pages/Dealsdetails';
import TermsConditions from './Components/Pages/Terms&conditions';
import PrivacyPolicy from './Components/Pages/Privacypolicy';
import DealsPage from './Components/Pages/Deals';
import Sockettest from './Components/Pages/Sockettest';
import CouponsPage from './Components/Pages/Coupons';
import StoreDealsPage from './Components/Pages/Stores/deals';
import FaqPage from './Components/Pages/Faq';
import ContactusPage from './Components/Pages/Contactus';
import SuperDealsPage from './Components/Pages/Superdeals';
import FavoritesPage from './Components/Pages/Favorites';
import LoadingOverlay from './Components/Tools/Loading';
import ScrollToTop from "react-scroll-to-top";
import LoadingRedirect from './Components/Tools/LoadingRedirect';
import FiltersPage from './Components/Pages/Filters';
import FilterButton from './Components/Tools/Filter';
function App() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const isRedirectPage = location.pathname.startsWith('/ifsredirect'); 
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [location]);

  useEffect(() => {
    setLoading(true);
    
    const timer = setTimeout(() => {
      setLoading(false); 
    }, 200); 

    return () => clearTimeout(timer);
  }, [location]);



  return (
    <div className="App">
         {!isRedirectPage && <Header />}
      {loading ?
      (
        <LoadingOverlay/>
      ): (

        <>
        <FilterButton/>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/pages/about-us" element={<Aboutus />} />
          <Route exact path="/newsarticleslisting" element={<NewsArticle />} />
          <Route exact path="/banner" element={<Banner />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route
            exact
            path="/Details"
            element={<AuthRoute element={<BasicDetail />} />}
          />
          <Route
            exact
            path="/singleproduct/:slug"
            element={<SingleProduct />}
          />
           <Route
            path="/deals"
            element={<DealsPage />}
          />
           <Route
            path="/sockettest"
            element={<Sockettest />}
          />
          <Route
            path="/faqs"
            element={<FaqPage />}
          />
          <Route
            exact
            path="/coupons"
            element={<CouponsPage/>}
          />
          <Route exact path="/pages/sitemap" element={<SiteMap />} />
          <Route exact path="/pages/Terms-and-Conditions" element={<TermsConditions />} />
          <Route exact path="/pages/Privacy-Policy" element={<PrivacyPolicy />} />
          <Route exact path="/pages/Safety-Tips" element={<SafetyTips />} />
          <Route exact path="/stores" element={<Stores />} />
          <Route exact path="/stores/:id" element={<StoreDealsPage />} />
          <Route
            exact
            path="/newsarticle/:id"
            element={<NewsArticleSingle />}
          />
          <Route exact path="/profile" element={<Profile />} />
          <Route
            exact
            path="/dealsdetails/:id"
            element={<DealsDetails />}
          />
          <Route exact path="/ifsredirect/:id" element={<LoadingRedirect />} />
          <Route exact path='/contactus' element={ <ContactusPage/>} />
          <Route exact path="/superdeals" element={<SuperDealsPage/>} />
          <Route exact path="/favorites" element={<FavoritesPage/>} />
          <Route exact path='/filters' element={<FiltersPage/>}/>
        </Routes>
        {!isRedirectPage && <Footer />}
        </>
      )
    }
    </div>

        
  );
}
const WrappedApp = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
export default WrappedApp;
