 import axios from 'axios';


const API_BASE_URL = "https://www.indiafreestuff.in/ifs"; // Replace with your API base URL



 // axios.post("https://www.indiafreestuff.in/ifs/login", formdata,{
        //     headers: {
        //         'ifskey': 'DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr',
        //     }
        // })
        //     .then(function (response) {
        //         if (response.data.status == 'success') {
        //             console.log("response data", response.data.responseData)

        //             // setstateforoldvalues(response.data.getprevdata)
        //         } else {
        //             console.log('under error');
        //         }
        //     })

//  headers: {
//   'ifskey': 'DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr',
// }

  export const submitLoginForm = async (formData) =>{
    try {
      const response = await axios.post(`${API_BASE_URL}/login`, formData ,{
        // headers:{
        //   'ifskey': 'DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr',
          
        // }
      })
      console.log(response);
      // const response = LoginResponce;
      return response;
    } catch (error) {
      throw error;
    }
  }

  export const registerFormSubmit = async (formData)=>{
    try {
      const response = await axios.post(`${API_BASE_URL}/signup`, formData, {
        // headers:{
        //   'ifskey': 'DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr',
          
        // }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

   export const forgotpassFormSubmit = async (formData) => {
     try {
       const response = await axios.post(
         `${API_BASE_URL}/forgotpassword`,
         formData,
         {
          //  headers: {
          //    ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          //  },
         }
       );
       return response;
     } catch (error) {
       throw error;
     }
   };
  export const fetchContent = async(formData)=>{
    try{
      const response = await axios.post(`${API_BASE_URL}/contentpages`, formData, {
        // headers:{
        //   'ifskey': 'DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr',
        // },
        }
    );
      return response;
    } catch (error) {
      throw error;
    }
  }
  export const fetchbannerContent = async (formData) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/banner`,
        {
          // headers: {
          //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          // },
          params:  formData
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  export const newsarticleContent = async() =>{
    try{
      const response = await axios.get(`${API_BASE_URL}/newsarticles`, {
        // headers:{
        //   'ifskey': 'DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr',
        // }
      });
      return response
    }
    catch (error) {
      throw error;
    }
  }
  export const singlenewsarticleContent = async(formData) =>{
    try{
      const response = await axios.post(`${API_BASE_URL}/newsarticledetails`, formData, {
        // headers:{
        //   'ifskey': 'DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr',
        // }
      });
      return response
    }
    catch (error) {
      throw error;
    }
  }
  export const viewprofileapi = async(formData) =>{
    try{
      const response = await axios.post(`${API_BASE_URL}/myprofile`, formData, {
        // headers:{
        //   'ifskey': 'DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr',
        // }
      });
      return response.data
    }
    catch (error) {
      throw error;
    }
  }
  export const updateprofileapi = async(formData) =>{
    try{
      const response = await axios.post(`${API_BASE_URL}/updateprofile`, formData, {
        // headers:{
        //   'ifskey': 'DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr',
        // }
      });
      return response.data;
    }
    catch (error) {
      throw error;
    }
  }


  export const ChangepasswordSubmit = async(formData)=>{
    try{
      const response = await axios.post(`${API_BASE_URL}/changepassword`, formData, {
        // headers:{
        //   'ifskey': 'DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr',
        // }
      });
      return response;
    }
    catch(error){
      throw error;
    }
  }

  export const detailFormSubmit = async(formData)=>{
    try {
      //const response = await axios.post(`${API_BASE_URL}/registerUser/`);
      const response = {"status":"success"};
      return response;
    } catch (error) {
      throw error;
    }
  }

  // deals content
export const dealsContent = async (formData)=> {
  try{
    const { featured, limit, page, superdeal } = formData;
      const response = await axios.get(`${API_BASE_URL}/getdeals`, {
        // headers:{
        //   'ifskey': 'DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr',
        // },
        params:  formData
      });
      return response;
    }
    catch(error){
      throw error;
    }
}
  
  // deals details
  export const dealsDetailsContent = async (formData) => {
    try {
      const { slug } = formData;
      const response = await axios.get(`${API_BASE_URL}/dealdetails`, {
        // headers: {
        //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
        // },
        params: formData,
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  // deal of day content
  export const fetchdealofdayContent = async ()=> {
  try{

      const response = await axios.get(`${API_BASE_URL}/dealsofday`, {
        // headers: {
        //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
        // },
      });
      return response.data;
    }
    catch(error){
      throw error;
    }
}
// fetch categories
  export const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getcategories`, {
        // headers: {
        //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
        // },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  // fetch deals details
  export const fetchDealsByCategories = async (formData) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/catdetails`, {
        // headers: {
        //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
        // },
        params: formData,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // get fav delas
  export const fetchFavDeals = async (formData) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/myfavourites`,
        {
          // headers: {
          //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          // },
          params: formData,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  // add to fav dfeals
   export const addToFavDeals = async (formData) => {
     try {
       const response = await axios.post(
         `${API_BASE_URL}/addtofavourites`,
         formData,
         {
          //  headers: {
          //    ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          //  },
         }
       );
       return response;
     } catch (error) {
       throw error;
     }
   };
   // remove fav dfeals
   export const removeFavDeals = async (formData) => {
     try {
       const response = await axios.get(`${API_BASE_URL}/removefavourites`, {
        //  headers: {
        //    ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
        //  },
         params: formData,
       });
       return response;
     } catch (error) {
       throw error;
     }
   };
   
  //  like / dislike a deal
  export const dealLikeDisLike = async(formData) =>{
  try {
      const response = await axios.post(`${API_BASE_URL}/deallikes`, formData, {
        // headers: {
        //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
        // },
      });
    
       return response;
    }
    catch (error) {
       throw error;
     }
  }

  // fetch comments
   export const fetchPostComments = async(formData) =>{
  try {
      const response = await axios.post(`${API_BASE_URL}/getcomments`, formData, {
        // headers: {
        //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
        // },
      });
    
       return response.data;
    }
    catch (error) {
       throw error;
     }
  }

   export const postnewComment = async (formData) => {
     try {
       const response = await axios.post(
         `${API_BASE_URL}/commentspost`,
         formData,
         {
          //  headers: {
          //    ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          //  },
         }
       );
       return response;
     } catch (error) {
       throw error;
     }
   };
   export const commentlikedislike = async (formData) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/commentlikes`,
        formData,
        {
          // headers: {
          //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          // },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
   export const fetchHomeBanners = async (formData) => {
    try{
      const response = await axios.get(
        `${API_BASE_URL}/banner`,
        {
          // headers: {
          //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          // },
          params: formData,
        }
      );
      return response;
    }
    catch(error){
      throw error;
    }
   }

   export const fetchStores = async (formData) =>{
    try{
      const response = await axios.get(
        `${API_BASE_URL}/stores`,
        {
          // headers: {
          //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          // },
        }
      );
      return response;
    }
    catch(error){
      throw error;
    }
   }
   export const fetchFeaturedStores = async (formData) =>{
    try{
      const response = await axios.get(
        `${API_BASE_URL}/stores`,
        {
          // headers: {
          //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          // },
          params: formData,
        }
      );
      return response;
    }
    catch(error){
      throw error;
    }
   }
   export const fetchrecentcomments = async (formData) =>{
    try{
      const response = await axios.get(
        `${API_BASE_URL}/recentcomments`,
        {
          // headers: {
          //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          // },
          params: formData,
        }
      );
      return response;
    }
    catch(error){
      throw error;
    }
   }

   export const fetchfootercontent = async () =>{
    try{
      const response = await axios.get(
        `${API_BASE_URL}/getfooterdetails`,
        {
          // headers: {
          //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          // },
        }
      );
      return response;
    }
    catch(error){
      throw error;
    }
   }
   export const fetchcouponslist = async (formData) =>{
    try{
      const response = await axios.get(
        `${API_BASE_URL}/promocodes`,
        {
          // headers: {
          //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          // },
          params: formData,
        }
      );
      return response.data;
    }
    catch(error){
      throw error;
    }
   }
   export const fetchsimilardeals = async (formData) =>{
    try{
      const response = await axios.get(
        `${API_BASE_URL}/similarproducts`,
        {
          // headers: {
          //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          // },
          params: formData,

        }
      );
      return response.data;
    }
    catch(error){
      throw error;
    }
   }

   export const fetchSearchedDeals = async (formData) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/search`,
        {
          // headers: {
          //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          // },
          params: formData,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  export const fetchPrimeDealsTimer = async (formData) => {
    try{
      const response = await axios.get(
        `${API_BASE_URL}/commonsettings`,
        {
          // headers: {
          //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          // },
          params: formData,
        }
      );
      return response;
    }
    catch(err){
      throw err;
    }
  }


  export const fetchfeaturedCategories = async(formData) => {
    try{
      const response = await axios.get(
        `${API_BASE_URL}/featuredcats`,
        {
          // headers: {
          //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          // }
        }
      );
      return response.data;
    }
    catch(err){
      throw err;
    }
  }

  export const newcontactsubmit = async(formData) => {
    try{
      const response = await axios.post(
        `${API_BASE_URL}/contactus`,
        {
          // headers: {
          //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          // }
        },
         formData
      );
      return response.data;
    }
    catch(err){
      throw err;
    }
  }

  export const fetchcontactus = async() => {
    try{
      const response = await axios.get(
        `${API_BASE_URL}/contactus`,
        {
          // headers: {
          //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          // },
        }
      );
      return response.data;
    }
    catch(err){
      throw err;
    }
  }

  export const fetchdealscount = async(formData) => {
    try{
      const response = await axios.get(
        `${API_BASE_URL}/getcount`,
        {
          // headers: {
          //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          // },
          params: formData,
        }
      );
      return response.data;
    }
    catch(err){
      throw err;
    }
  }

  export const fetchfiltereddeals = async(formData)=>{
    try{
      const response = await axios.post(
        `${API_BASE_URL}/filters`,
        
        formData 
      );
    console.log(response);
      return response.data;
    }
    catch(err){
      throw err;
    }
  }

  export const redirectdeals = async(formData) => {
    try{
      const response = await axios.post(
        `${API_BASE_URL}/redirection`,
          // headers: {
          //   ifskey: "DFbWd3d5erOK8fec6BPGyuOG8HWcHfdr",
          // },
      formData,
      );
      return response.data;
    }
    catch(err){
      throw err;
    }
  }
