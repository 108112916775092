import React, {useEffect, useState} from "react";
// import { viewprofile, updateprofile } from '../../../Reducer/api';
import UserProfile from "../../Tools/Profile";
import EditProfile from "../../Tools/EditProfile";
import ChangePassword from "../../Tools/Changepassword";
import { ToastContainer, toast } from "react-toastify";
import { viewProfile, updateProfile, clearAction } from "../../../Reducer/ProfileSlice";
import { useDispatch, useSelector } from "react-redux";
export default function Profile() {
    const dispatch = useDispatch();
    const userprofileData = useSelector((state)=> state.profile.data);
    const usersuccessData = useSelector((state)=> state.profile.success);
    const userupdatesuccessData = useSelector((state)=> state.profile.updatesuccess);
    const usererrorData = useSelector((state)=> state.profile.error);
    const userexists = localStorage.getItem("user");


    const [ user, setUser] = useState(null);
    useEffect(()=>{
      if (userexists) {
        const id = JSON.parse(userexists).id;
      let formData = new FormData();
      


      let arrdata=JSON.parse(userexists);
        if(arrdata.length>=0)
        {
          console.log("sunil0",arrdata)
          console.log("sunildata",arrdata[0]['ID'])
          let google_id=arrdata[0]['ID'];
          formData.append('userid', google_id);
        }
        else{
          formData.append('userid', id);
        }
      

      dispatch(viewProfile(formData))

      }      
    },[userexists, userupdatesuccessData])
    useEffect(()=>{
      if(userupdatesuccessData){
        toast.success('Profile Edited successfully');
      }
      if(usererrorData){
        console.log(usererrorData);
      }
    },[userupdatesuccessData, usererrorData]);
    useEffect(()=>{
      console.log("userprofiledataa",userprofileData)
      console.log("usersuccessData",usersuccessData)

      if(userprofileData && usersuccessData){
console.log("userprofiledataa",userprofileData)

        setUser(userprofileData);
        dispatch(clearAction())
      }
    }, [usersuccessData]);
    const updateUserProfile = async (formdata) => {
      // let response = await updateprofile(formdata);
      // if (response.status == "success") {
      //   toast.success(response.responseData.message);
      //   setUser(response.responseData.data);
      // }
      dispatch(updateProfile(formdata));
    };
    return (
      <>
        <section className="heading text-center">
          <div className="container">
            <div className="heading_in uuu">
              <h2>Profile</h2>
            </div>
          </div>
        </section>

        <section className="profile">
          <div className="container">
            <div className="row">
              <div className="col-md-4 profile_side">
                {user && <UserProfile user={user} />}
              </div>

              <div className="col-md-8 profile_full">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="editProfile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#editProfile"
                      type="button"
                      role="tab"
                      aria-controls="editProfile"
                      aria-selected="false"
                    >
                      Edit Profile
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="changepassword-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#changepassword"
                      type="button"
                      role="tab"
                      aria-controls="changepassword"
                      aria-selected="false"
                    >
                      Change password
                    </button>
                  </li>
                  {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Reviews-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Reviews"
                      type="button"
                      role="tab"
                      aria-controls="Reviews"
                      aria-selected="false"
                    >
                      Reviews
                    </button>
                  </li> */}
                </ul>
                <div className="tab-content" id="myTabContent">
                  {/* <div
                    className="tab-pane fade show active"
                    id="Downloads"
                    role="tabpanel"
                    aria-labelledby="Downloads-tab"
                  >
                    <ul className="download">
                      <li>
                        <a href="#">
                          <i className="fas fa-arrow-alt-circle-right"></i>{" "}
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. <i className="fas fa-download"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-arrow-alt-circle-right"></i>{" "}
                          Vivamus at risus aliquet, congue arcu non, aliquam
                          leo. <i className="fas fa-download"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-arrow-alt-circle-right"></i>{" "}
                          Aenean facilisis mi id lorem ullamcorper, vitae
                          aliquam eros malesuada.{" "}
                          <i className="fas fa-download"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-arrow-alt-circle-right"></i>{" "}
                          Aliquam ac tortor bibendum, sollicitudin est et,
                          ultrices tellus.<i className="fas fa-download"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-arrow-alt-circle-right"></i>{" "}
                          Proin accumsan urna nec risus egestas, venenatis
                          tincidunt odio pellentesque.{" "}
                          <i className="fas fa-download"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-arrow-alt-circle-right"></i>{" "}
                          Integer ut ex sed lorem molestie vehicula.{" "}
                          <i className="fas fa-download"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-arrow-alt-circle-right"></i>{" "}
                          Donec fringilla lacus sit amet orci congue elementum.{" "}
                          <i className="fas fa-download"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-arrow-alt-circle-right"></i> Sed
                          pharetra magna eget tortor vehicula hendrerit.{" "}
                          <i className="fas fa-download"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-arrow-alt-circle-right"></i>{" "}
                          Curabitur malesuada urna ut elementum dapibus.{" "}
                          <i className="fas fa-download"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-arrow-alt-circle-right"></i>{" "}
                          Maecenas id eros euismod, porta est sed, maximus erat.{" "}
                          <i className="fas fa-download"></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                  {user && <EditProfile user={user} updateUserProfile={updateUserProfile} />}
                  {/* <div
                    className="tab-pane fade"
                    id="editProfile"
                    role="tabpanel"
                    aria-labelledby="changepassword-tab"
                  >
                    <div className="row mt-4">
                      <div className="col-sm-6">
                        <label>Neme</label>
                        <input
                          type="text"
                          placeholder="Enetr name"
                          className="form-control"
                        />
                      </div>

                      <div className="col-sm-6">
                        <label>Phone</label>
                        <input
                          type="text"
                          placeholder="Enter phone number"
                          className="form-control"
                        />
                      </div>

                      <div className="col-sm-6">
                        <label>Profession</label>
                        <input
                          type="text"
                          placeholder="Profession"
                          className="form-control"
                        />
                      </div>

                      <div className="col-sm-6">
                        <button type="button">Submit</button>
                      </div>
                    </div>
                  </div> */}
                  {user && <ChangePassword id={user.id} />}
                  {/* <div
                    className="tab-pane fade"
                    id="Reviews"
                    role="tabpanel"
                    aria-labelledby="Reviews-tab"
                  >
                    <div className="test">
                      <img src="images/sing_user.png" alt="search-u" />
                      <p className="mb-2">
                        <b>WalkerboyUK</b> <span>18th July</span>
                      </p>
                      <h4>
                        <i className="fas fa-star"></i> 4.8
                      </h4>
                      <p>
                        I've been having an argument with Chiquitos for the last
                        couple of weeks regarding this offer and the fact the
                        photo they use to advertise them in their emails is
                        nothing like what you get served.Their photo shows them
                        with salsa &amp; sour cream.
                      </p>
                      <h6>
                        <i className="far fa-thumbs-up"></i> Like
                      </h6>
                    </div>

                    <div className="test">
                      <img src="images/sing_user.png" alt="search-u" />
                      <p className="mb-2">
                        <b>WalkerboyUK</b> <span>18th July</span>
                      </p>
                      <h4>
                        <i className="fas fa-star"></i> 4.8
                      </h4>
                      <p>
                        I've been having an argument with Chiquitos for the last
                        couple of weeks regarding this offer and the fact the
                        photo they use to advertise them in their emails is
                        nothing like what you get served.Their photo shows them
                        with salsa &amp; sour cream.
                      </p>
                      <h6>
                        <i className="far fa-thumbs-up"></i> Like
                      </h6>
                    </div>

                    <div className="test">
                      <img src="images/sing_user.png" alt="search-u" />
                      <p className="mb-2">
                        <b>WalkerboyUK</b> <span>18th July</span>
                      </p>
                      <h4>
                        <i className="fas fa-star"></i> 4.8
                      </h4>
                      <p>
                        I've been having an argument with Chiquitos for the last
                        couple of weeks regarding this offer and the fact the
                        photo they use to advertise them in their emails is
                        nothing like what you get served.Their photo shows them
                        with salsa &amp; sour cream.
                      </p>
                      <h6>
                        <i className="far fa-thumbs-up"></i> Like
                      </h6>
                    </div>

                    <div className="test">
                      <img src="images/sing_user.png" alt="search-u" />
                      <p className="mb-2">
                        <b>WalkerboyUK</b> <span>18th July</span>
                      </p>
                      <h4>
                        <i className="fas fa-star"></i> 4.8
                      </h4>
                      <p>
                        I've been having an argument with Chiquitos for the last
                        couple of weeks regarding this offer and the fact the
                        photo they use to advertise them in their emails is
                        nothing like what you get served.Their photo shows them
                        with salsa &amp; sour cream.
                      </p>
                      <h6>
                        <i className="far fa-thumbs-up"></i> Like
                      </h6>
                    </div>

                    <div className="test">
                      <img src="images/sing_user.png" alt="search-u" />
                      <p className="mb-2">
                        <b>WalkerboyUK</b> <span>18th July</span>
                      </p>
                      <h4>
                        <i className="fas fa-star"></i> 4.8
                      </h4>
                      <p>
                        I've been having an argument with Chiquitos for the last
                        couple of weeks regarding this offer and the fact the
                        photo they use to advertise them in their emails is
                        nothing like what you get served.Their photo shows them
                        with salsa &amp; sour cream.
                      </p>
                      <h6>
                        <i className="far fa-thumbs-up"></i> Like
                      </h6>
                    </div>

                    <div className="test">
                      <img src="images/sing_user.png" alt="search-u" />
                      <p className="mb-2">
                        <b>WalkerboyUK</b> <span>18th July</span>
                      </p>
                      <h4>
                        <i className="fas fa-star"></i> 4.8
                      </h4>
                      <p>
                        I've been having an argument with Chiquitos for the last
                        couple of weeks regarding this offer and the fact the
                        photo they use to advertise them in their emails is
                        nothing like what you get served.Their photo shows them
                        with salsa &amp; sour cream.
                      </p>
                      <h6>
                        <i className="far fa-thumbs-up"></i> Like
                      </h6>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
}