import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { clearStatus, loginForm } from '../../../Reducer/authSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator'
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import {LoginSocialTwitter} from 'reactjs-social-login';
import { useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingOverlay from '../../Tools/Loading';
import NameEmailModal from '../../Tools/NameEmailModal';
export default function Login({ show, onClose }) {
    const loginrespodata = useSelector(state => state.auth.loginrespodata);
    const successdeta = useSelector(state => state.auth.success);
    const errordata = useSelector(state => state.auth.error);
    const [ isvalidEmail, setIsValidEmail] = useState(true);
    const [ isvalidPassword, setIsValidPassword] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);
   const [showForgotPasswordModal, setShowForgotPasswordModal] =
     useState(false);
    const [sidebardt, sidebarstate] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState({ name: '', email: '' }); // State to store name and email
    const [userId, setUserId] = useState(null);
  const openForgotPasswordModal = () => {
    onClose(); 
  };

  const closeForgotPasswordModal = () => {
    setShowForgotPasswordModal(false);
  };

    const [logincred, setStatelogin] = useState({
        email: "",
        password: "",
    });
   
    useEffect(() => {
      
      if (errordata == 500 && loginrespodata) {
     
        setLoading(false);
        toast(loginrespodata.responseData.message);
        dispatch(clearStatus());
      } 
      
      else if (successdeta === true && loginrespodata) {
        console.log("loginresponse",loginrespodata);
               localStorage.setItem("user", JSON.stringify(loginrespodata.responseData.data));
          localStorage.setItem('comment_username', JSON.stringify(loginrespodata.responseData.data.name));
          localStorage.setItem('comment_email', JSON.stringify(loginrespodata.responseData.data.email));
              toast(loginrespodata.responseData.message);
        setStatelogin({ email: "", password: "" });
        dispatch(clearStatus());
        setLoading(false);
        onClose();
        window.location.reload();
          navigate("/"); 
        // const providerData = loginrespodata.responseData.data[0]?.PROVIDER;
        // if (providerData) {
        //   console.log(providerData);
        //   console.log(loginrespodata.responseData.data[0]);
        //   setUserId(loginrespodata.responseData.data[0]?.ID); // Set the user ID
        //   setUserData({
        //     name: loginrespodata.responseData.data[0]?.NAME || '',
        //     email: loginrespodata.responseData.data[0]?.EMAIL || ''
        //   });
        // onClose();
        //   setModalVisible(true); 
        // } else {
        //   localStorage.setItem("user", JSON.stringify(loginrespodata.responseData.data));
        //   localStorage.setItem('comment_username', JSON.stringify(loginrespodata.responseData.data.name));
        //   localStorage.setItem('comment_email', JSON.stringify(loginrespodata.responseData.data.email));
        // toast(loginrespodata.responseData.message);
        // setStatelogin({ email: "", password: "" });
        // dispatch(clearStatus());
        // setLoading(false);
        // onClose();
        // window.location.reload();
        //   navigate("/"); 
        // }
     
      }
      else{
        console.log("responsedatacomes",successdeta)
 
      }
    }, [errordata, successdeta])
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setUserData({ ...userData, [name]: value });
    };
  
    const handleProfileSubmit = (e) => {
      e.preventDefault();
      // Handle the form submission, such as updating the user data
      console.log("Submitted User Data: ", userData);
      setModalVisible(false); // Close the modal after submission
      navigate("/"); // Redirect after submission
    };

    
    // if (successdeta && successdeta.status) {
    //     console.log("testtt", successdeta.status)
    // }


    // console.log("**********",loginrespodata.responseData)
    const handleChange = (e) => {
        const { name, value } = e.target
        if(name === 'email' ){
            setStatelogin(prevState => ({
                ...prevState,
                [name]: value
            }));
            setIsValidEmail(validator.isEmail(value));
        }
        else if(name === 'password' ){
            setStatelogin(prevState => ({
                ...prevState,
                [name]: value
            }));
            setIsValidPassword((validator.isLength(value, { min: 8 })));
        }
    }
  //   FB.getLoginStatus(function(response) {
  //     statusChangeCallback(response);
  // });
  // const handleFacebookLogin = async () => {
  //   try {
  //     window.FB.login(
  //       function (response) {
  //         if (response.authResponse) {
  //           //Here it will provide USERID and ACCESSTOKEN 
  //         }
  //       },
  //       {
  //         scope: "email, pages_show_list,public_profile",
  //       }
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
 
  // const googlelogin = useGoogleLogin({
  //   onSuccess: (response) => {
  //   console.log("googleresponse",response);
  //   let formdata = new FormData();
  //   formdata.append('provider', 'google');
  //   formdata.append('access_token', response.access_token);
  //   formdata.append('type', 'social');
  //   dispatch(loginForm(formdata));
  //   },
  //   onError: (error) => console.log("googleloginerror",error),
  //   scope: "profile email",
  //   access_type: "offline", 
  //   prompt: "consent",
  //   // flow: "auth-code",
  //   flow: "implicit",
  // });

  const googlelogin = useGoogleLogin({
    onSuccess: async (response) => {
      console.log("Google Login Response:", response);
  
      const accessToken = response.access_token;
  
      // Fetch user details from Google UserInfo API
      try {
        const userInfoResponse = await fetch(
          `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${accessToken}`
        );
  
        if (!userInfoResponse.ok) {
          throw new Error("Failed to fetch user information");
        }
  
        const userInfo = await userInfoResponse.json();
        console.log("Google User Info:", userInfo);
  
        // Prepare FormData
        let formdata = new FormData();
        formdata.append('provider', 'google');
        formdata.append('access_token', accessToken);
        formdata.append('type', 'social');
  
        // Add user details if needed
        formdata.append('email', userInfo.email);
        formdata.append('name', userInfo.name);
        formdata.append('image', userInfo.picture);
        formdata.append('uid', userInfo.id);
  
        // Dispatch login form data
        dispatch(loginForm(formdata));
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    },
    onError: (error) => console.log("Google Login Error:", error),
    scope: "profile email",
    access_type: "offline",
    prompt: "consent",
    flow: "implicit",
  });
  


  const facebooklogin = () => {
    try {
        window.FB.login(
            function (response) {
                if (response.authResponse) {
                    console.log(response.authResponse);
                    let formdata = new FormData();
                  formdata.append('provider', 'facebook');
                  formdata.append('uid', response.authResponse.userID);
                  formdata.append('type', 'social');
                  dispatch(loginForm(formdata));
                }
            }
            // {
            //     scope: "email, pages_show_list,public_profile",
            // }
        );
    } catch (error) {
        console.log(error)
    }
}

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if(!isvalidEmail ){
            toast.error("Please Enter Valid Email Address");
        }
        if(logincred.email === ''){
            toast.error("Email Address Can't be empty");

        }
        if(!isvalidPassword){
            toast.error("Password must be atleast 8 character long");
        }
        if(logincred.password === ''){
            toast.error("Password Can't be empty");
        }
        if(isvalidEmail && isvalidPassword){
        let formdata = new FormData();
        formdata.append('email', logincred.email);
        formdata.append('password', logincred.password);
        dispatch(loginForm(formdata));
        }
    }
    return (
      <>
      {loading && <LoadingOverlay/>}
      <div
        class={`modal  ${show ? "fade show" : ""}`}
        style={{ display: show ? 'block' : 'none' }}
        id="exampleModalsign"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered loginpage">
          <div class="modal-content">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div class="modal-body p-0">
              <div class="form-signin">
                <form onSubmit={handleSubmit}>
                  <h2>Sign In</h2>
                  <div class="form-group">
                    <input
                      type="email"
                      onChange={handleChange}
                      name="email"
                      value={logincred.email}
                      class="form-control"
                      placeholder="Enter your email ID"
                      required=""
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      name="password"
                      value={logincred.password}
                      onChange={handleChange}
                      class="form-control"
                      placeholder=" Password"
                      required=""
                    />
                  </div>
                  <button
                    class="btn btn-lg btn-warning btn-block"
                    type="submit"
                  >
                    Login
                  </button>
                  <div class="row checkbox text-left">
                    <div class="col-6">
                      <label>
                        <input type="checkbox" value="remember-me" /> Remember
                        me
                      </label>
                    </div>
                    <div class="col-6 text-right">
                      <a
                        href=""
                        data-bs-toggle="modal"
                        data-bs-target="#forgotpassword"
                        onClick={openForgotPasswordModal}
                      >
                        Forgot Your Password ?
                      </a>
                    </div>
                  </div>
                  <h6>Or Signin with</h6>
                  <ul class="social">
                    <li>
                      <a href="javascript: void(0)" onClick={facebooklogin}>
                        <img src="assets/images/fb.png" alt="icon" /> Facebook
                      </a>
                    </li>
                    <li>
                      <a href="javascript: void(0)" onClick={googlelogin}>
                        <img src="assets/images/google.png" alt="icon" /> Google
                      </a>
                    </li>
                    {/* <LoginSocialTwitter
                      client_id="LDz5aRioiaSt33xLKOI7zqJr0"
                      // client_secret = "EdX1g2ao2nrBoYdqLcWxlG5IPMYZsKEC6XNnY2oagqsY2zqF1p"
                      // client_secret={process.env.REACT_APP_TWITTER_V2_APP_SECRET || ''}
                      redirect_uri="http://localhost:3000/callback"
                      // onLoginStart={onLoginStart}
                      // onLogoutSuccess={onLogoutSuccess}
                      //     onResolve={
                      //         console.log("working")
                      //     //     ({ provider, data }) => {
                      //     //     setProvider(provider);
                      //     //     setProfile(data);
                      //     // }
                      // }
                      onReject={(err) => {
                        console.log(err);
                      }}
                    > */}
                      <li>
                        <a href="javascript: void(0)">
                          <img src="assets/images/tw.png" alt="icon" /> Twitter
                        </a>
                      </li>
                    {/* </LoginSocialTwitter> */}
                  </ul>
                  <p class="mb-0 text-center">
                    Don’t have an account ?{" "}
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal2"
                      href=""
                      onClick={openForgotPasswordModal}
                    >
                      Sign Up
                    </a>
                  </p>
                 
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {modalVisible && (
        <NameEmailModal
          onClose={() => setModalVisible(false)}
          userId={userId}
          userData={userData} 
          handleInputChange={handleInputChange}
          handleSubmit={handleProfileSubmit}
        />
      )} */}
      
        <NameEmailModal
        show={modalVisible}
          onClose={() => setModalVisible(false)}  
          userId={userId}                         
          userData={userData}                     // Pass the form data for name and email
          handleInputChange={handleInputChange}   // Handle input changes
          handleSubmit={handleProfileSubmit}      // Handle form submission
        />
      </>
    );

}






