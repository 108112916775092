import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {viewprofileapi, updateprofileapi} from './api';

export const viewProfile= createAsyncThunk('profile/viewProfile', async (formData, { rejectWithValue }) => {
  try {
    const response = await viewprofileapi(formData);
console.log("sunil",response)
console.log("sunilform",formData)

    return response.responseData.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const updateProfile= createAsyncThunk('profile/updateProfile', async (formData, { rejectWithValue }) => {
    try {
      const response = await updateprofileapi(formData);
      return response.responseData;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });
const ProfileSlice = createSlice({
    name: 'profile',
    initialState: {
      submitting: false,
      error: null,
      success: false,
      data:"",
      updatesuccess: false
    },
    reducers: {
        clearAction: (state) => {
          state.success = false;
          state.error = null;
          state.updatesuccess = false;
        },
      },
    extraReducers: builder => {
      builder
        .addCase(viewProfile.pending, (state) => {
          state.submitting = true;
          state.error = null;
          state.success = false;
        })
        .addCase(viewProfile.fulfilled, (state,action) => {
          state.submitting = false;
          state.data= action.payload;
          state.success=true;
          state.error = null;
        })
        .addCase(viewProfile.rejected, (state, action) => {
          state.submitting = false;
          state.error = action.payload;
          state.success = false;
        })
        .addCase(updateProfile.pending, (state) => {
            state.submitting = true;
            state.error = null;
            state.updatesuccess = false;
          })
          .addCase(updateProfile.fulfilled, (state,action) => {
            state.submitting = false;
            state.updatesuccess =true;
            state.error = null;
          })
          .addCase(updateProfile.rejected, (state, action) => {
            state.submitting = false;
            state.error = action.payload;
            state.updatesuccess  = false;
          })
    },
  });

  export default ProfileSlice.reducer;
  export const { clearAction } = ProfileSlice.actions;