import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import { changeLanguage, setToken, setUser } from './Reducer/authSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TostOptions } from './data/content';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { FacebookProvider} from 'react-facebook';
import { createRoot } from "react-dom/client";
import ScrollToTop from 'react-scroll-to-top';

const user = localStorage.getItem('user')!=='undefined' ? JSON.parse(localStorage.getItem('user')):"";
const token = localStorage.getItem('token') ? localStorage.getItem('token') :"";

if (user && token) {
  store.dispatch(setUser(user));
  store.dispatch(setToken(token));
}

const container = document.getElementById("root");
const root = createRoot(container); 
root.render(
  <GoogleOAuthProvider clientId="82918444346-1jlhu8kpep2vsgemgmrqdb9p193us866.apps.googleusercontent.com">
  {/* <GoogleOAuthProvider clientId="58931365507-p2ovmq8rss04ukvik05q1gd0556tfe4l.apps.googleusercontent.com"> */}
    <Provider store={store}>
      <ToastContainer {...TostOptions}/>
      <App />
      <ScrollToTop/>
    </Provider>
    </GoogleOAuthProvider>
);
